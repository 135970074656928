/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentDTO } from './DocumentDTO';
import {
    DocumentDTOFromJSON,
    DocumentDTOFromJSONTyped,
    DocumentDTOToJSON,
} from './DocumentDTO';

/**
 * 
 * @export
 * @interface CircularResponseDTO
 */
export interface CircularResponseDTO {
    /**
     * The technical id. Will be ignored when sent by post-request.
     * 
     * @type {number}
     * @memberof CircularResponseDTO
     */
    id?: number;
    /**
     * The title of the circular
     * 
     * @type {string}
     * @memberof CircularResponseDTO
     */
    title?: string;
    /**
     * The text of the circular.
     * 
     * @type {string}
     * @memberof CircularResponseDTO
     */
    text?: string;
    /**
     * The timestamp when the circular have been saved
     * 
     * @type {Date}
     * @memberof CircularResponseDTO
     */
    createdAt?: Date;
    /**
     * The timestamp when the circular have been updated
     * 
     * @type {Date}
     * @memberof CircularResponseDTO
     */
    updatedAt?: Date;
    /**
     * The timestamp when the circular have been sent
     * 
     * @type {Date}
     * @memberof CircularResponseDTO
     */
    sentAt?: Date;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof CircularResponseDTO
     */
    attachments?: Array<DocumentDTO>;
}

/**
 * Check if a given object implements the CircularResponseDTO interface.
 */
export function instanceOfCircularResponseDTO(value: object): value is CircularResponseDTO {
    return true;
}

export function CircularResponseDTOFromJSON(json: any): CircularResponseDTO {
    return CircularResponseDTOFromJSONTyped(json, false);
}

export function CircularResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CircularResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'text': json['text'] == null ? undefined : json['text'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
        'sentAt': json['sentAt'] == null ? undefined : (new Date(json['sentAt'])),
        'attachments': json['attachments'] == null ? undefined : ((json['attachments'] as Array<any>).map(DocumentDTOFromJSON)),
    };
}

export function CircularResponseDTOToJSON(value?: CircularResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'text': value['text'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
        'sentAt': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'attachments': value['attachments'] == null ? undefined : ((value['attachments'] as Array<any>).map(DocumentDTOToJSON)),
    };
}

