/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepresentativeRequestStateDTO
 */
export interface RepresentativeRequestStateDTO {
    /**
     * The E-Mail of the representative, only admin is allowed to set the value.
     * 
     * @type {string}
     * @memberof RepresentativeRequestStateDTO
     */
    email?: string;
    /**
     * The state of the representative-request. If true, then representative-request will be marked accepted. Otherwise the representative-request will be marked declined.
     * 
     * @type {boolean}
     * @memberof RepresentativeRequestStateDTO
     */
    accepted: boolean;
}

/**
 * Check if a given object implements the RepresentativeRequestStateDTO interface.
 */
export function instanceOfRepresentativeRequestStateDTO(value: object): value is RepresentativeRequestStateDTO {
    if (!('accepted' in value) || value['accepted'] === undefined) return false;
    return true;
}

export function RepresentativeRequestStateDTOFromJSON(json: any): RepresentativeRequestStateDTO {
    return RepresentativeRequestStateDTOFromJSONTyped(json, false);
}

export function RepresentativeRequestStateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentativeRequestStateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'accepted': json['accepted'],
    };
}

export function RepresentativeRequestStateDTOToJSON(value?: RepresentativeRequestStateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'accepted': value['accepted'],
    };
}

