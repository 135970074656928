/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentDTO } from './DocumentDTO';
import {
    DocumentDTOFromJSON,
    DocumentDTOFromJSONTyped,
    DocumentDTOToJSON,
} from './DocumentDTO';

/**
 * 
 * @export
 * @interface CircularDTO
 */
export interface CircularDTO {
    /**
     * The technical id. Will be ignored when sent by post-request.
     * 
     * @type {number}
     * @memberof CircularDTO
     */
    id?: number;
    /**
     * The version of a circular.
     * 
     * @type {number}
     * @memberof CircularDTO
     */
    version?: number;
    /**
     * The title of the circular
     * 
     * @type {string}
     * @memberof CircularDTO
     */
    title: string;
    /**
     * The text of the circular.
     * 
     * @type {string}
     * @memberof CircularDTO
     */
    text: string;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof CircularDTO
     */
    attachments?: Array<DocumentDTO>;
}

/**
 * Check if a given object implements the CircularDTO interface.
 */
export function instanceOfCircularDTO(value: object): value is CircularDTO {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    return true;
}

export function CircularDTOFromJSON(json: any): CircularDTO {
    return CircularDTOFromJSONTyped(json, false);
}

export function CircularDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CircularDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : json['version'],
        'title': json['title'],
        'text': json['text'],
        'attachments': json['attachments'] == null ? undefined : ((json['attachments'] as Array<any>).map(DocumentDTOFromJSON)),
    };
}

export function CircularDTOToJSON(value?: CircularDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': value['version'],
        'title': value['title'],
        'text': value['text'],
        'attachments': value['attachments'] == null ? undefined : ((value['attachments'] as Array<any>).map(DocumentDTOToJSON)),
    };
}

