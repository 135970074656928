/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RepresentativeDTO } from './RepresentativeDTO';
import {
    RepresentativeDTOFromJSON,
    RepresentativeDTOFromJSONTyped,
    RepresentativeDTOToJSON,
} from './RepresentativeDTO';
import type { DocumentDTO } from './DocumentDTO';
import {
    DocumentDTOFromJSON,
    DocumentDTOFromJSONTyped,
    DocumentDTOToJSON,
} from './DocumentDTO';

/**
 * 
 * @export
 * @interface TripDTO
 */
export interface TripDTO {
    /**
     * The technical id. Will be ignored when sent by post-request.
     * 
     * @type {number}
     * @memberof TripDTO
     */
    id?: number;
    /**
     * The version of trip.
     * 
     * @type {number}
     * @memberof TripDTO
     */
    version?: number;
    /**
     * 
     * @type {RepresentativeDTO}
     * @memberof TripDTO
     */
    representative?: RepresentativeDTO;
    /**
     * The title of the trip
     * 
     * @type {string}
     * @memberof TripDTO
     */
    title: string;
    /**
     * The textual description of the trip. This will be the body of the email, sent to the assigned fanclub-members.
     * 
     * @type {string}
     * @memberof TripDTO
     */
    text?: string;
    /**
     * The trips destination.
     * 
     * @type {string}
     * @memberof TripDTO
     */
    destination: string;
    /**
     * Starting date of the trip
     * 
     * @type {Date}
     * @memberof TripDTO
     */
    startDate: Date;
    /**
     * End date of the trip
     * 
     * @type {Date}
     * @memberof TripDTO
     */
    endDate: Date;
    /**
     * Date up to which the trip can be booked
     * 
     * @type {Date}
     * @memberof TripDTO
     */
    bookableUntil?: Date;
    /**
     * an  info-text about the nights accommodation in the hotel
     * 
     * @type {string}
     * @memberof TripDTO
     */
    hotelAccommodation: string;
    /**
     * Information about the departure points of the trip
     * 
     * @type {string}
     * @memberof TripDTO
     */
    departurePoints: string;
    /**
     * Information about the trip price
     * 
     * @type {string}
     * @memberof TripDTO
     */
    price: string;
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof TripDTO
     */
    documents?: Array<DocumentDTO>;
    /**
     * the infotext about the uploaded trip documents
     * 
     * @type {string}
     * @memberof TripDTO
     */
    documentDescription?: string;
}

/**
 * Check if a given object implements the TripDTO interface.
 */
export function instanceOfTripDTO(value: object): value is TripDTO {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('destination' in value) || value['destination'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('hotelAccommodation' in value) || value['hotelAccommodation'] === undefined) return false;
    if (!('departurePoints' in value) || value['departurePoints'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    return true;
}

export function TripDTOFromJSON(json: any): TripDTO {
    return TripDTOFromJSONTyped(json, false);
}

export function TripDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : json['version'],
        'representative': json['representative'] == null ? undefined : RepresentativeDTOFromJSON(json['representative']),
        'title': json['title'],
        'text': json['text'] == null ? undefined : json['text'],
        'destination': json['destination'],
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'bookableUntil': json['bookableUntil'] == null ? undefined : (new Date(json['bookableUntil'])),
        'hotelAccommodation': json['hotelAccommodation'],
        'departurePoints': json['departurePoints'],
        'price': json['price'],
        'documents': json['documents'] == null ? undefined : ((json['documents'] as Array<any>).map(DocumentDTOFromJSON)),
        'documentDescription': json['documentDescription'] == null ? undefined : json['documentDescription'],
    };
}

export function TripDTOToJSON(value?: TripDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': value['version'],
        'representative': RepresentativeDTOToJSON(value['representative']),
        'title': value['title'],
        'text': value['text'],
        'destination': value['destination'],
        'startDate': ((value['startDate']).toISOString().substring(0,10)),
        'endDate': ((value['endDate']).toISOString().substring(0,10)),
        'bookableUntil': value['bookableUntil'] == null ? undefined : ((value['bookableUntil']).toISOString().substring(0,10)),
        'hotelAccommodation': value['hotelAccommodation'],
        'departurePoints': value['departurePoints'],
        'price': value['price'],
        'documents': value['documents'] == null ? undefined : ((value['documents'] as Array<any>).map(DocumentDTOToJSON)),
        'documentDescription': value['documentDescription'],
    };
}

