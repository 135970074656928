/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RepresentativeMinimalDTO } from './RepresentativeMinimalDTO';
import {
    RepresentativeMinimalDTOFromJSON,
    RepresentativeMinimalDTOFromJSONTyped,
    RepresentativeMinimalDTOToJSON,
} from './RepresentativeMinimalDTO';

/**
 * 
 * @export
 * @interface TripListDTO
 */
export interface TripListDTO {
    /**
     * The technical id. Will be ignored when sent by post-request.
     * 
     * @type {number}
     * @memberof TripListDTO
     */
    id?: number;
    /**
     * 
     * @type {RepresentativeMinimalDTO}
     * @memberof TripListDTO
     */
    representative?: RepresentativeMinimalDTO;
    /**
     * The title of the trip
     * 
     * @type {string}
     * @memberof TripListDTO
     */
    title?: string;
    /**
     * The trips destination.
     * 
     * @type {string}
     * @memberof TripListDTO
     */
    destination?: string;
    /**
     * Starting date of the trip
     * 
     * @type {Date}
     * @memberof TripListDTO
     */
    startDate?: Date;
    /**
     * End date of the trip
     * 
     * @type {Date}
     * @memberof TripListDTO
     */
    endDate?: Date;
    /**
     * an  info-text about the nights accommodation in the hotel
     * 
     * @type {string}
     * @memberof TripListDTO
     */
    hotelAccommodation?: string;
    /**
     * Information about the departure points of the trip
     * 
     * @type {string}
     * @memberof TripListDTO
     */
    departurePoints?: string;
    /**
     * Information about the trip price
     * 
     * @type {string}
     * @memberof TripListDTO
     */
    price?: string;
}

/**
 * Check if a given object implements the TripListDTO interface.
 */
export function instanceOfTripListDTO(value: object): value is TripListDTO {
    return true;
}

export function TripListDTOFromJSON(json: any): TripListDTO {
    return TripListDTOFromJSONTyped(json, false);
}

export function TripListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripListDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'representative': json['representative'] == null ? undefined : RepresentativeMinimalDTOFromJSON(json['representative']),
        'title': json['title'] == null ? undefined : json['title'],
        'destination': json['destination'] == null ? undefined : json['destination'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'hotelAccommodation': json['hotelAccommodation'] == null ? undefined : json['hotelAccommodation'],
        'departurePoints': json['departurePoints'] == null ? undefined : json['departurePoints'],
        'price': json['price'] == null ? undefined : json['price'],
    };
}

export function TripListDTOToJSON(value?: TripListDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'representative': RepresentativeMinimalDTOToJSON(value['representative']),
        'title': value['title'],
        'destination': value['destination'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString().substring(0,10)),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString().substring(0,10)),
        'hotelAccommodation': value['hotelAccommodation'],
        'departurePoints': value['departurePoints'],
        'price': value['price'],
    };
}

