/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepresentativeRequestDTO
 */
export interface RepresentativeRequestDTO {
    /**
     * The technical id. Will be created after a request is saved.
     * 
     * @type {number}
     * @memberof RepresentativeRequestDTO
     */
    id?: number;
    /**
     * The city where the requester lives.
     * 
     * @type {string}
     * @memberof RepresentativeRequestDTO
     */
    city?: string;
    /**
     * The region where the requester wants to be responsible for.
     * 
     * @type {string}
     * @memberof RepresentativeRequestDTO
     */
    region?: string;
    /**
     * The firstname of the requester. This will be determined from the users token.
     * 
     * @type {string}
     * @memberof RepresentativeRequestDTO
     */
    firstname?: string;
    /**
     * The surname of the requester. This will be determined from the users token.
     * 
     * @type {string}
     * @memberof RepresentativeRequestDTO
     */
    surname?: string;
    /**
     * A free-text which can be used by the requester.
     * 
     * @type {string}
     * @memberof RepresentativeRequestDTO
     */
    notice?: string;
}

/**
 * Check if a given object implements the RepresentativeRequestDTO interface.
 */
export function instanceOfRepresentativeRequestDTO(value: object): value is RepresentativeRequestDTO {
    return true;
}

export function RepresentativeRequestDTOFromJSON(json: any): RepresentativeRequestDTO {
    return RepresentativeRequestDTOFromJSONTyped(json, false);
}

export function RepresentativeRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentativeRequestDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'city': json['city'] == null ? undefined : json['city'],
        'region': json['region'] == null ? undefined : json['region'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'surname': json['surname'] == null ? undefined : json['surname'],
        'notice': json['notice'] == null ? undefined : json['notice'],
    };
}

export function RepresentativeRequestDTOToJSON(value?: RepresentativeRequestDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'city': value['city'],
        'region': value['region'],
        'firstname': value['firstname'],
        'surname': value['surname'],
        'notice': value['notice'],
    };
}

