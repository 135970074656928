module.exports = [{
      plugin: require('../node_modules/@dfb/gatsby-dfb-theme/gatsby-browser.js'),
      options: {"plugins":[],"excludeScrollHandling":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DFB - Deutscher Fußball-Bund e.V.","short_name":"DFB - Deutscher Fußball-Bund e.V.","start_url":"/","icon":"src/images/DFB_Picturemark.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5155d9a17dfdfa910854212630306582"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
