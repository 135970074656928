/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberMinimalDTO
 */
export interface MemberMinimalDTO {
    /**
     * The technical id
     * 
     * @type {number}
     * @memberof MemberMinimalDTO
     */
    id?: number;
    /**
     * True, if the current user acts as a representative, otherwise false
     * 
     * @type {boolean}
     * @memberof MemberMinimalDTO
     */
    representative?: boolean;
}

/**
 * Check if a given object implements the MemberMinimalDTO interface.
 */
export function instanceOfMemberMinimalDTO(value: object): value is MemberMinimalDTO {
    return true;
}

export function MemberMinimalDTOFromJSON(json: any): MemberMinimalDTO {
    return MemberMinimalDTOFromJSONTyped(json, false);
}

export function MemberMinimalDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberMinimalDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'representative': json['representative'] == null ? undefined : json['representative'],
    };
}

export function MemberMinimalDTOToJSON(value?: MemberMinimalDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'representative': value['representative'],
    };
}

