/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepresentativeMinimalDTO
 */
export interface RepresentativeMinimalDTO {
    /**
     * The technical id. Will be ignored when sent by post-request.
     * 
     * @type {number}
     * @memberof RepresentativeMinimalDTO
     */
    id?: number;
    /**
     * The E-Mail of a representative.
     * 
     * @type {string}
     * @memberof RepresentativeMinimalDTO
     */
    email?: string;
}

/**
 * Check if a given object implements the RepresentativeMinimalDTO interface.
 */
export function instanceOfRepresentativeMinimalDTO(value: object): value is RepresentativeMinimalDTO {
    return true;
}

export function RepresentativeMinimalDTOFromJSON(json: any): RepresentativeMinimalDTO {
    return RepresentativeMinimalDTOFromJSONTyped(json, false);
}

export function RepresentativeMinimalDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentativeMinimalDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function RepresentativeMinimalDTOToJSON(value?: RepresentativeMinimalDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
    };
}

