/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepresentativeDTO
 */
export interface RepresentativeDTO {
    /**
     * The technical id. Will be ignored when sent by post- or Put-request.
     * 
     * @type {number}
     * @memberof RepresentativeDTO
     */
    id?: number;
    /**
     * The entity-version. Will be ignored when sent by post- or Put-request
     * 
     * @type {number}
     * @memberof RepresentativeDTO
     */
    version?: number;
    /**
     * The counts of to a representative assigned members.
     * 
     * @type {number}
     * @memberof RepresentativeDTO
     */
    memberCount?: number;
    /**
     * indicates if the user is assigned to this representative.
     * 
     * @type {boolean}
     * @memberof RepresentativeDTO
     */
    userAssigned?: boolean;
    /**
     * The E-Mail of a representative.
     * 
     * @type {string}
     * @memberof RepresentativeDTO
     */
    email?: string;
    /**
     * The firstname of a representative
     * 
     * @type {string}
     * @memberof RepresentativeDTO
     */
    firstName?: string;
    /**
     * The lastname of a representative
     * 
     * @type {string}
     * @memberof RepresentativeDTO
     */
    lastName?: string;
    /**
     * The description of a representative profile
     * 
     * @type {string}
     * @memberof RepresentativeDTO
     */
    description?: string;
    /**
     * The region where the representative is responsible for.
     * 
     * @type {string}
     * @memberof RepresentativeDTO
     */
    region?: string;
}

/**
 * Check if a given object implements the RepresentativeDTO interface.
 */
export function instanceOfRepresentativeDTO(value: object): value is RepresentativeDTO {
    return true;
}

export function RepresentativeDTOFromJSON(json: any): RepresentativeDTO {
    return RepresentativeDTOFromJSONTyped(json, false);
}

export function RepresentativeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentativeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'version': json['version'] == null ? undefined : json['version'],
        'memberCount': json['memberCount'] == null ? undefined : json['memberCount'],
        'userAssigned': json['userAssigned'] == null ? undefined : json['userAssigned'],
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'description': json['description'] == null ? undefined : json['description'],
        'region': json['region'] == null ? undefined : json['region'],
    };
}

export function RepresentativeDTOToJSON(value?: RepresentativeDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': value['version'],
        'memberCount': value['memberCount'],
        'userAssigned': value['userAssigned'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'description': value['description'],
        'region': value['region'],
    };
}

