import React, { createContext, useContext, useReducer, useEffect } from "react"
import { getUserData, USER_ACTIONS } from "./userHelpers"

export const UserContext = createContext(null)

const reducer = (state, action) => {
  switch (action.type) {
    case USER_ACTIONS.LOADING:
      return { ...state, loading: true, user: null, error: null }
    case USER_ACTIONS.SUCCESS:
      return { ...state, loading: false, user: action.payload, error: null }
    case USER_ACTIONS.ERROR:
      return { ...state, loading: false, user: null, error: action.payload }
    default:
      throw new Error("Invalid action type")
  }
}

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    user: null,
    error: null,
  })

  const handleUserData = async () => {
    try {
      const token = localStorage.getItem("kc_token")
      if (token) {
        const userInfo = await getUserData(token)
        dispatch({ type: USER_ACTIONS.SUCCESS, payload: userInfo })
      }
    } catch (error) {
      console.error("Error initializing user state", error)
      dispatch({ type: USER_ACTIONS.ERROR, payload: error })
    }
  }

  useEffect(() => {
    const initData = async () => {
      dispatch({ type: USER_ACTIONS.LOADING })
      handleUserData()
    }

    initData()

    window.addEventListener("auth-success", handleUserData)

    return () => {
      window.removeEventListener("auth-success", handleUserData)
    }
  }, [])

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
