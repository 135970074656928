/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * The technical id. Will be ignored when sent by post-request.
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    id?: number;
    /**
     * The name of a document
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    name?: string;
    /**
     * The content of a document
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    content?: string;
    /**
     * The link to the document
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    link?: string;
    /**
     * The size of a document in KB
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    type?: string;
    /**
     * True if the file exists on Blobstorage, false if not. This can be an indicator of a malware-file.
     * 
     * @type {boolean}
     * @memberof DocumentDTO
     */
    available?: boolean;
}

/**
 * Check if a given object implements the DocumentDTO interface.
 */
export function instanceOfDocumentDTO(value: object): value is DocumentDTO {
    return true;
}

export function DocumentDTOFromJSON(json: any): DocumentDTO {
    return DocumentDTOFromJSONTyped(json, false);
}

export function DocumentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'content': json['content'] == null ? undefined : json['content'],
        'link': json['link'] == null ? undefined : json['link'],
        'size': json['size'] == null ? undefined : json['size'],
        'type': json['type'] == null ? undefined : json['type'],
        'available': json['available'] == null ? undefined : json['available'],
    };
}

export function DocumentDTOToJSON(value?: DocumentDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'content': value['content'],
        'link': value['link'],
        'size': value['size'],
        'type': value['type'],
        'available': value['available'],
    };
}

