/* tslint:disable */
/* eslint-disable */
/**
 * DFB FANCLUB API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CircularDTO,
  CircularListResponseDTO,
  CircularResponseDTO,
  ErrorResponse,
  FilterEnum,
  MemberMinimalDTO,
  RepresentativeDTO,
  RepresentativeRequestDTO,
  RepresentativeRequestStateDTO,
  TripDTO,
  TripListDTO,
} from '../models/index';
import {
    CircularDTOFromJSON,
    CircularDTOToJSON,
    CircularListResponseDTOFromJSON,
    CircularListResponseDTOToJSON,
    CircularResponseDTOFromJSON,
    CircularResponseDTOToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FilterEnumFromJSON,
    FilterEnumToJSON,
    MemberMinimalDTOFromJSON,
    MemberMinimalDTOToJSON,
    RepresentativeDTOFromJSON,
    RepresentativeDTOToJSON,
    RepresentativeRequestDTOFromJSON,
    RepresentativeRequestDTOToJSON,
    RepresentativeRequestStateDTOFromJSON,
    RepresentativeRequestStateDTOToJSON,
    TripDTOFromJSON,
    TripDTOToJSON,
    TripListDTOFromJSON,
    TripListDTOToJSON,
} from '../models/index';

export interface AddDocumentToCircularRequest {
    id: number;
    file?: Blob;
}

export interface AddDocumentToTripRequest {
    id: number;
    file?: Blob;
}

export interface AllTripsRequest {
    filter: FilterEnum;
}

export interface AssignRepresentativesRequest {
    requestBody?: Array<number>;
}

export interface CreateCircularRequest {
    circularDTO?: CircularDTO;
}

export interface CreateOrUpdateRepresentativeRequestRequest {
    representativeRequestDTO?: RepresentativeRequestDTO;
}

export interface CreateTripRequest {
    tripDTO?: TripDTO;
}

export interface DeleteCircularRequest {
    id: number;
}

export interface DeleteDocumentFromCircularRequest {
    id: number;
    docId: number;
}

export interface DeleteDocumentFromTripRequest {
    id: number;
    docId: number;
}

export interface DeleteRepresentativeRequest {
    id: number;
}

export interface DeleteTripRequest {
    id: number;
}

export interface GetCircularRequest {
    id: number;
}

export interface GetRepresentativeRequest {
    id: number;
}

export interface GetRepresentativeRequestRequest {
    id: number;
}

export interface GetTripRequest {
    id: number;
}

export interface PatchRepresentativeRequestRequest {
    id: number;
    representativeRequestStateDTO?: RepresentativeRequestStateDTO;
}

export interface SendCircularMailRequest {
    id: number;
    preview: boolean;
}

export interface UpdateCircularRequest {
    id: number;
    circularDTO?: CircularDTO;
}

export interface UpdateRepresentativeRequest {
    id: number;
    representativeDTO?: RepresentativeDTO;
}

export interface UpdateTripRequest {
    id: number;
    tripDTO?: TripDTO;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Adds a document to an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async addDocumentToCircularRaw(requestParameters: AddDocumentToCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CircularResponseDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addDocumentToCircular().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/circular/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CircularResponseDTOFromJSON(jsonValue));
    }

    /**
     * Adds a document to an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async addDocumentToCircular(requestParameters: AddDocumentToCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CircularResponseDTO> {
        const response = await this.addDocumentToCircularRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a document to an existing trip identified by its technical id. This request is only allowed for the representative that have created the trip. 
     */
    async addDocumentToTripRaw(requestParameters: AddDocumentToTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TripDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addDocumentToTrip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/trip/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripDTOFromJSON(jsonValue));
    }

    /**
     * Adds a document to an existing trip identified by its technical id. This request is only allowed for the representative that have created the trip. 
     */
    async addDocumentToTrip(requestParameters: AddDocumentToTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripDTO> {
        const response = await this.addDocumentToTripRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all representative-requests of the fan club. This request is only allowed for an administrator 
     */
    async allRepresentativeRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepresentativeRequestDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/representatives/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepresentativeRequestDTOFromJSON));
    }

    /**
     * Returns all representative-requests of the fan club. This request is only allowed for an administrator 
     */
    async allRepresentativeRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepresentativeRequestDTO>> {
        const response = await this.allRepresentativeRequestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns all representatives of the fan club. 
     */
    async allRepresentativesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepresentativeDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/representatives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepresentativeDTOFromJSON));
    }

    /**
     * Returns all representatives of the fan club. 
     */
    async allRepresentatives(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepresentativeDTO>> {
        const response = await this.allRepresentativesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns all to filter state matching trips. Administrators are not allowed to execute this request. 
     */
    async allTripsRaw(requestParameters: AllTripsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TripListDTO>>> {
        if (requestParameters['filter'] == null) {
            throw new runtime.RequiredError(
                'filter',
                'Required parameter "filter" was null or undefined when calling allTrips().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trips`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TripListDTOFromJSON));
    }

    /**
     * Returns all to filter state matching trips. Administrators are not allowed to execute this request. 
     */
    async allTrips(requestParameters: AllTripsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TripListDTO>> {
        const response = await this.allTripsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assigns one or more representative(s) to an existing member. This request acts also for removing assignment from a representative. All passed representative-ids to assign are remaining. 
     */
    async assignRepresentativesRaw(requestParameters: AssignRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/member/representatives`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assigns one or more representative(s) to an existing member. This request acts also for removing assignment from a representative. All passed representative-ids to assign are remaining. 
     */
    async assignRepresentatives(requestParameters: AssignRepresentativesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignRepresentativesRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a new circular. The circular will only be saved, but not sent to recipients. Can only be executed by a fanclub-representative. 
     */
    async createCircularRaw(requestParameters: CreateCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CircularResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/circular`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CircularDTOToJSON(requestParameters['circularDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CircularResponseDTOFromJSON(jsonValue));
    }

    /**
     * Creates a new circular. The circular will only be saved, but not sent to recipients. Can only be executed by a fanclub-representative. 
     */
    async createCircular(requestParameters: CreateCircularRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CircularResponseDTO> {
        const response = await this.createCircularRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new or updates an existing request of a fanclub-member to become a fanclub-representative. This request is only allowed to be executed by a fanclub-member, that is not registered as a fanclub-representative yet. 
     */
    async createOrUpdateRepresentativeRequestRaw(requestParameters: CreateOrUpdateRepresentativeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepresentativeRequestDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/representatives/request`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeRequestDTOToJSON(requestParameters['representativeRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeRequestDTOFromJSON(jsonValue));
    }

    /**
     * Creates a new or updates an existing request of a fanclub-member to become a fanclub-representative. This request is only allowed to be executed by a fanclub-member, that is not registered as a fanclub-representative yet. 
     */
    async createOrUpdateRepresentativeRequest(requestParameters: CreateOrUpdateRepresentativeRequestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepresentativeRequestDTO> {
        const response = await this.createOrUpdateRepresentativeRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new trip. The content will be used to send an e-mail to the fanclub-members assigned to the representative. Not allowed for an administrator. 
     */
    async createTripRaw(requestParameters: CreateTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TripDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TripDTOToJSON(requestParameters['tripDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripDTOFromJSON(jsonValue));
    }

    /**
     * Creates a new trip. The content will be used to send an e-mail to the fanclub-members assigned to the representative. Not allowed for an administrator. 
     */
    async createTrip(requestParameters: CreateTripRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripDTO> {
        const response = await this.createTripRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async deleteCircularRaw(requestParameters: DeleteCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCircular().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/circular/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async deleteCircular(requestParameters: DeleteCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCircularRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a document from an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async deleteDocumentFromCircularRaw(requestParameters: DeleteDocumentFromCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CircularResponseDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDocumentFromCircular().'
            );
        }

        if (requestParameters['docId'] == null) {
            throw new runtime.RequiredError(
                'docId',
                'Required parameter "docId" was null or undefined when calling deleteDocumentFromCircular().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/circular/{id}/documents/{docId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"docId"}}`, encodeURIComponent(String(requestParameters['docId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CircularResponseDTOFromJSON(jsonValue));
    }

    /**
     * Removes a document from an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async deleteDocumentFromCircular(requestParameters: DeleteDocumentFromCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CircularResponseDTO> {
        const response = await this.deleteDocumentFromCircularRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a document from an existing trip identified by its technical id. This request is only allowed for the representative that have created the trip. 
     */
    async deleteDocumentFromTripRaw(requestParameters: DeleteDocumentFromTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TripDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDocumentFromTrip().'
            );
        }

        if (requestParameters['docId'] == null) {
            throw new runtime.RequiredError(
                'docId',
                'Required parameter "docId" was null or undefined when calling deleteDocumentFromTrip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trip/{id}/documents/{docId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"docId"}}`, encodeURIComponent(String(requestParameters['docId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripDTOFromJSON(jsonValue));
    }

    /**
     * Removes a document from an existing trip identified by its technical id. This request is only allowed for the representative that have created the trip. 
     */
    async deleteDocumentFromTrip(requestParameters: DeleteDocumentFromTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripDTO> {
        const response = await this.deleteDocumentFromTripRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing representative identified by its technical id. This Request is only allowed for Administrators! 
     */
    async deleteRepresentativeRaw(requestParameters: DeleteRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteRepresentative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/representative/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing representative identified by its technical id. This Request is only allowed for Administrators! 
     */
    async deleteRepresentative(requestParameters: DeleteRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRepresentativeRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes an existing trip identified by its technical id. The deleting of a trip is only allowed for the representative that have created the trip. 
     */
    async deleteTripRaw(requestParameters: DeleteTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTrip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trip/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing trip identified by its technical id. The deleting of a trip is only allowed for the representative that have created the trip. 
     */
    async deleteTrip(requestParameters: DeleteTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTripRaw(requestParameters, initOverrides);
    }

    /**
     * Gets an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async getCircularRaw(requestParameters: GetCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CircularResponseDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCircular().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/circular/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CircularResponseDTOFromJSON(jsonValue));
    }

    /**
     * Gets an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async getCircular(requestParameters: GetCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CircularResponseDTO> {
        const response = await this.getCircularRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of circulars created by the current representative. Only representatives are allowed to execute this request. 
     */
    async getCircularsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CircularListResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/circulars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CircularListResponseDTOFromJSON));
    }

    /**
     * Get a list of circulars created by the current representative. Only representatives are allowed to execute this request. 
     */
    async getCirculars(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CircularListResponseDTO>> {
        const response = await this.getCircularsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns information if the current member acts as a fanclub-representative. 
     */
    async getMemberRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberMinimalDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/member`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberMinimalDTOFromJSON(jsonValue));
    }

    /**
     * Returns information if the current member acts as a fanclub-representative. 
     */
    async getMember(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberMinimalDTO> {
        const response = await this.getMemberRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delivers an existing representative-request of the current logged-in fanclub-member. Only a fanclub-member can request its own request. 
     */
    async getOwnRepresentativeRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepresentativeRequestDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/representatives/request`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeRequestDTOFromJSON(jsonValue));
    }

    /**
     * Delivers an existing representative-request of the current logged-in fanclub-member. Only a fanclub-member can request its own request. 
     */
    async getOwnRepresentativeRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepresentativeRequestDTO> {
        const response = await this.getOwnRepresentativeRequestRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets an existing representative identified by its technical id. 
     */
    async getRepresentativeRaw(requestParameters: GetRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepresentativeDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRepresentative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/representative/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeDTOFromJSON(jsonValue));
    }

    /**
     * Gets an existing representative identified by its technical id. 
     */
    async getRepresentative(requestParameters: GetRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepresentativeDTO> {
        const response = await this.getRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a single representative-request identified by its technical id. This request is only allowed for an administrator. 
     */
    async getRepresentativeRequestRaw(requestParameters: GetRepresentativeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepresentativeRequestDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRepresentativeRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/representatives/request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeRequestDTOFromJSON(jsonValue));
    }

    /**
     * Returns a single representative-request identified by its technical id. This request is only allowed for an administrator. 
     */
    async getRepresentativeRequest(requestParameters: GetRepresentativeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepresentativeRequestDTO> {
        const response = await this.getRepresentativeRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets an existing trip identified by its technical id. Not allowed for an administrator. 
     */
    async getTripRaw(requestParameters: GetTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TripDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getTrip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/trip/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripDTOFromJSON(jsonValue));
    }

    /**
     * Gets an existing trip identified by its technical id. Not allowed for an administrator. 
     */
    async getTrip(requestParameters: GetTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripDTO> {
        const response = await this.getTripRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This request is intended to accept or decline the defined representative-request. If the request should be accepted, an email-address in the passed DTO is required! This request is only allowed for an administrator. 
     */
    async patchRepresentativeRequestRaw(requestParameters: PatchRepresentativeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling patchRepresentativeRequest().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/representatives/request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeRequestStateDTOToJSON(requestParameters['representativeRequestStateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This request is intended to accept or decline the defined representative-request. If the request should be accepted, an email-address in the passed DTO is required! This request is only allowed for an administrator. 
     */
    async patchRepresentativeRequest(requestParameters: PatchRepresentativeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchRepresentativeRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Sends an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async sendCircularMailRaw(requestParameters: SendCircularMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendCircularMail().'
            );
        }

        if (requestParameters['preview'] == null) {
            throw new runtime.RequiredError(
                'preview',
                'Required parameter "preview" was null or undefined when calling sendCircularMail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['preview'] != null) {
            queryParameters['preview'] = requestParameters['preview'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/circular/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async sendCircularMail(requestParameters: SendCircularMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCircularMailRaw(requestParameters, initOverrides);
    }

    /**
     * Updates an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async updateCircularRaw(requestParameters: UpdateCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CircularResponseDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCircular().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/circular/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CircularDTOToJSON(requestParameters['circularDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CircularResponseDTOFromJSON(jsonValue));
    }

    /**
     * Updates an existing circular identified by its technical id. Only representatives are allowed to execute this request. This request can only be executed by the representative that have created the circular. 
     */
    async updateCircular(requestParameters: UpdateCircularRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CircularResponseDTO> {
        const response = await this.updateCircularRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing representative. Can only be executed by the owning representative. 
     */
    async updateRepresentativeRaw(requestParameters: UpdateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepresentativeDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateRepresentative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/representative/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeDTOToJSON(requestParameters['representativeDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeDTOFromJSON(jsonValue));
    }

    /**
     * Updates an existing representative. Can only be executed by the owning representative. 
     */
    async updateRepresentative(requestParameters: UpdateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepresentativeDTO> {
        const response = await this.updateRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing trip. The updating of a trip is only allowed for the representative that have created the trip. 
     */
    async updateTripRaw(requestParameters: UpdateTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TripDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTrip().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/trip/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TripDTOToJSON(requestParameters['tripDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripDTOFromJSON(jsonValue));
    }

    /**
     * Updates an existing trip. The updating of a trip is only allowed for the representative that have created the trip. 
     */
    async updateTrip(requestParameters: UpdateTripRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripDTO> {
        const response = await this.updateTripRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
