import { DefaultApi, Configuration } from "./api"

const defaultApi = new DefaultApi(
  new Configuration({
    basePath: process.env.DFB_DB_FANCLUB_API_URL,
  }),
)

export const apiClient = {
  getRepresentative: defaultApi.getRepresentative.bind(defaultApi),
  allRepresentatives: defaultApi.allRepresentatives.bind(defaultApi),
  getTrip: defaultApi.getTrip.bind(defaultApi),
  deleteTrip: defaultApi.deleteTrip.bind(defaultApi),
  allTrips: defaultApi.allTrips.bind(defaultApi),
  getCircular: defaultApi.getCircular.bind(defaultApi),
  getCirculars: defaultApi.getCirculars.bind(defaultApi),
}

export const getApiClient = (token: string) =>
  new DefaultApi(
    new Configuration({
      basePath: process.env.DFB_DB_FANCLUB_API_URL,
      headers: {
        Authorization: `bearer ${token}`,
      },
    }),
  )
